import { forwardRef, ReactNode } from 'react'
import { IconDotsVertical, TablerIconsProps } from '@tabler/icons-react'
import { IconButton } from '../IconButton/IconButton.tsx'

export const MoreActions = forwardRef<HTMLButtonElement>((props, ref) => {
  return <IconButton icon={IconDotsVertical} ref={ref} {...props} />
})

const cache = new Map<any, any>()

export const customIconButton = (
  icon: (props: TablerIconsProps) => ReactNode
) => {
  if (!cache.has(icon)) {
    cache.set(
      icon,
      forwardRef<HTMLButtonElement>((props, ref) => {
        return <IconButton icon={icon} ref={ref} {...props} />
      })
    )
  }

  return cache.get(icon)
}
