import { CSSProperties, FC, ReactNode } from 'react'
import cx from 'classnames'
import './Toolbar.scss'

export const Toolbar: FC<{
  children: ReactNode
  alignRight?: boolean
  style?: CSSProperties
  className?: string
}> = ({ children, alignRight = false, style, className }) => {
  return (
    <div
      className={cx('toolbar', alignRight && 'right', className)}
      style={style}
    >
      {children}
    </div>
  )
}
