import { useSearchParams } from 'react-router-dom'
import { useCallback, useMemo, useRef, useState } from 'react'

export const useSearchParam = <T>(
  key: string,
  {
    defaultValue,
    format,
    parse,
    localState = false,
  }: {
    defaultValue: T
    parse: (str: string) => T
    format: (value: T) => string
    localState?: boolean
  }
): [T, (value: T) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [localValue, setLocalValue] = useState(defaultValue)
  const state = useRef({
    defaultValue,
    format,
    parse,
    key,
    localState,
    setSearchParams,
  })

  state.current.defaultValue = defaultValue
  state.current.format = format
  state.current.parse = parse
  state.current.key = key
  state.current.localState = localState
  state.current.setSearchParams = setSearchParams

  const setter = useCallback(
    (newValue: T) => {
      if (state.current.localState) {
        setLocalValue(newValue)
        return
      }

      const newStringValue = state.current.format(newValue)

      state.current.setSearchParams((params) => {
        if (
          newStringValue === state.current.format(state.current.defaultValue)
        ) {
          params.delete(key)
        } else {
          params.set(key, newStringValue)
        }
        return params
      })
    },
    [key]
  )

  const stringValue = searchParams.get(key)
  const stringDefaultValue = JSON.stringify(defaultValue)
  const value = useMemo(() => {
    if (state.current.localState) {
      return localValue
    }

    return stringValue === null
      ? state.current.defaultValue
      : state.current.parse(stringValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringValue, stringDefaultValue, localValue])

  return [value, setter]
}
