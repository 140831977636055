import { FC, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import style from './CasualLink.module.scss'
import cx from 'classnames'

export const CasualLink: FC<{ children: ReactNode } & LinkProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Link {...props} className={cx(className, style.casualLink)}>
      {children}
    </Link>
  )
}
