import { useForm } from 'react-hook-form'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper'
import { TextInput } from '../../../../components/input/TextInput/TextInput'
import { RouteObject } from 'react-router-dom'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { Table } from '../../../../components/component/Table/Table.tsx'
import { ColumnDef } from '@tanstack/react-table'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { Code } from '../../../../components/component/Code/Code.tsx'
import { openModal } from '../../../../components/component/Modal/Modal.tsx'
import { NewProjectForm } from '../../../../components/layout/AppLayout/AppLayout.tsx'
import { Dropdown } from '../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import { useDeleteProject } from '../../../../queries/projects.tsx'
import { graphql } from '../../../../gql'
import { useEffect, useMemo } from 'react'
import Case from 'case'
import { useQueryClient } from '@tanstack/react-query'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { LongTextInput } from '../../../../components/input/LongTextInput/LongTextInput.tsx'
import { fetchUser } from '../../../../auth'
import { Truncate } from '../../../../components/component/Truncate/Truncate.tsx'
import {
  MultiSelectInput,
  SelectInput,
} from '../../../../components/input/SelectInput/SelectInput.tsx'
import { Columns } from '../../../../components/component/Columns/Columns.tsx'
import { TeamRole } from '../../../../gql/graphql.ts'

const InformationForm = () => {
  const { organization } = useCurrentOrgSafe()

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      name: organization.name,
      allowSelfSignup: organization.allowSelfSignup,
      defaultRoleIds: organization.roles
        .filter((role) => role.default)
        .map((role) => role.id),
    },
  })

  const allowSelfSignup = watch('allowSelfSignup')

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateOrganizationInformation(
        $id: ID!
        $information: OrganizationInformation!
      ) {
        organization(id: $id) {
          updateInformation(information: $information) {
            id
            name
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      information:
        organization.myAggregatedRole.team === TeamRole.See
          ? { name: data.name }
          : data,
    }),
    onSuccess: () => {
      toast.success('Organization updated')
      fetchUser()
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Columns>
        <InputWrapper
          component={TextInput}
          control={control}
          name="name"
          label="Name"
          rules={{ required: true }}
        />
        {organization.domain && (
          <InputWrapper
            // @ts-ignore
            component={SelectInput}
            control={control}
            name="allowSelfSignup"
            label={`Who can join ${organization.name}?`}
            disabled={organization.myAggregatedRole.team === TeamRole.See}
            options={[
              {
                value: false,
                label: 'Only invited users',
                tooltip: 'You will need to invite everyone individually',
              },
              {
                value: true,
                label: `All @${organization.domain} emails`,
                tooltip: `Users with an email address ending with @${organization.domain} can automatically join ${organization.name} (if you have enough seats left). You can still invite other users manually.`,
              },
            ]}
          />
        )}
      </Columns>
      {allowSelfSignup && (
        <InputWrapper
          // @ts-ignore
          component={MultiSelectInput}
          control={control}
          name="defaultRoleIds"
          label="Default roles"
          disabled={organization.myAggregatedRole.team === TeamRole.See}
          tooltip={`These roles will be automatically assigned to new @${organization.domain} users.`}
          options={organization.roles.map((role) => ({
            label: role.name,
            value: role.id,
          }))}
        />
      )}
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Update"
      />
    </form>
  )
}

const ProjectForm = ({ id }: { id: string }) => {
  const { organization } = useCurrentOrgSafe()
  const project = organization.projects.find((p) => p.id === id)

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { touchedFields, isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      description: project?.description ?? '',
      slug: project?.slug ?? '',
      name: project?.name ?? '',
    },
  })

  const name = watch('name')

  useEffect(() => {
    if (!touchedFields.slug) {
      setValue('slug', Case.camel(name))
      clearErrors('slug')
    }
  }, [clearErrors, name, setValue, touchedFields.slug])

  const queryClient = useQueryClient()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation editProject($id: ID!, $data: ProjectInformation!) {
        project(id: $id) {
          updateInformation(information: $data) {
            id
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id,
      data,
    }),
    onSuccess: () => {
      toast.success('Project updated')
      queryClient.invalidateQueries({ queryKey: ['project', id] })
      fetchUser()
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        label="Name"
        name="name"
        component={TextInput}
        control={control}
        rules={{ required: true }}
        autoFocus
      />
      <InputWrapper
        label="Key"
        name="slug"
        tooltip="The key is used inside the code to uniquely identify projects."
        component={TextInput}
        control={control}
        rules={{ required: true }}
        className="monospace"
      />
      <InputWrapper
        label="Description"
        name="description"
        component={LongTextInput}
        control={control}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Update"
      />
    </form>
  )
}

const Page = () => {
  const { organization } = useCurrentOrgSafe()

  const columns = useMemo<ColumnDef<(typeof organization.projects)[0]>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.name + row.description,
        header: 'Name',
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>
            <div>
              <em>{row.original.name}</em>
            </div>
            <small>
              <Truncate text={row.original.description} length={100} />
            </small>
          </div>
        ),
      },
      {
        id: 'key',
        accessorFn: (row) => row.slug,
        header: 'Key',
        enableColumnFilter: false,
        cell: ({ row }) => <Code value={row.original.slug} />,
      },
    ],
    []
  )

  const deleteProject = useDeleteProject()

  return (
    <Sections>
      <Sections.Section title="Information">
        <InformationForm />
      </Sections.Section>
      <hr />
      <Sections.Section title="Projects">
        <Table
          data={organization.projects}
          noCustomColumns
          columns={columns}
          defaultSorting={[{ id: 'name', desc: false }]}
          onCreate={() =>
            openModal({
              title: 'New project',
              content: <NewProjectForm />,
            })
          }
          unit="projects"
          moreActions={({ row }) => (
            <>
              <Dropdown.Item
                label="Edit"
                icon={IconPencil}
                onClick={() =>
                  openModal({
                    title: 'Edit project',
                    content: <ProjectForm id={row.original.id} />,
                  })
                }
              />
              <hr />
              <Dropdown.Item
                label="Delete"
                icon={IconTrash}
                danger
                disabled={organization.projects.length === 1}
                tooltip={
                  organization.projects.length === 1
                    ? 'You cannot delete your last project'
                    : undefined
                }
                onClick={() => deleteProject(row.original.id)}
              />
            </>
          )}
        />
      </Sections.Section>
    </Sections>
  )
}

export const organizationRoute: RouteObject = {
  path: 'organization',
  element: <Page />,
}
