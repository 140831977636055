import { RouteObject } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { Meter } from '../../../../components/component/Meter/Meter.tsx'
import style from './Usage.module.scss'
import { Card } from '../../../../components/component/Card/Card.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { IconLockOpen } from '@tabler/icons-react'
import {
  Area,
  Bar,
  BarProps,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'
import { FormattedDate } from 'react-intl'
import dashboardImage from '../../../../assets/empty-state/dashboard.svg'
import Balancer from 'react-wrap-balancer'
import { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { SwitchInput } from '../../../../components/input/SwitchInput/SwitchInput.tsx'
import { SimpleTooltip } from '../../../../components/component/MonitoringChart/MonitoringTooltip.tsx'

export const RoundedBar = ({ fill, x, y, width, height }: BarProps) => {
  return <rect x={x} y={y} width={width} height={height} rx={5} fill={fill} />
}

const shortDateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
})
const longDateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'long',
})

export const DailyRequests = ({
  projectId,
  defaultShowDaily = Boolean(projectId),
}: {
  projectId?: string
  defaultShowDaily?: boolean
}) => {
  const { organization, project } = useCurrentOrgSafe()

  const { data } = useQuery({
    queryKey: ['organization', organization.id, 'daily requests'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query dailyRequests($id: ID!) {
            organization(id: $id) {
              id
              projects {
                id
                name
                usageHistory {
                  currentPeriod {
                    requests
                    date
                    cumulatedRequests
                  }
                }
              }
            }
          }
        `),
        { id: organization.id }
      ),
  })

  const [showDaily, setShowDaily] = useState(defaultShowDaily)

  const graphData = useMemo(() => {
    const projects = data?.organization?.projects
    return projects
      ? projects.reduce(
          (acc, cur) => {
            if (projectId && cur.id !== projectId) return acc

            cur.usageHistory.currentPeriod.forEach(
              ({ requests, cumulatedRequests }, i) => {
                acc[i].requests += requests
                acc[i][cur.id + 'requests'] = requests
                if (acc[i].cumulatedRequests !== null) {
                  acc[i].cumulatedRequests += cumulatedRequests
                  acc[i][cur.id + 'cumulatedRequests'] = cumulatedRequests
                }
              }
            )
            return acc
          },
          projects[0].usageHistory.currentPeriod.map(({ date }) => ({
            date,
            requests: 0,
            cumulatedRequests:
              date <= format(new Date(), 'yyyy-MM-dd') ? 0 : null,
          })) as Record<string, any>[]
        )
      : []
  }, [data?.organization?.projects, projectId])

  useEffect(() => {
    if (graphData[0]?.date === format(new Date(), 'yyyy-MM-dd')) {
      setShowDaily(true)
    }
  }, [graphData])

  return (
    <Card variant="outlined">
      <div className={style.period}>
        <FormattedDate value={graphData[0]?.date} day="numeric" month="short" />{' '}
        -{' '}
        <FormattedDate
          value={graphData[graphData.length - 1]?.date}
          day="numeric"
          month="short"
        />
      </div>
      <h2>Billing period requests</h2>
      {data?.organization?.projects?.every((project) =>
        project.usageHistory.currentPeriod.every(({ requests }) => !requests)
      ) ? (
        <div className={style.requestsEmptyState}>
          <img src={dashboardImage} />
          <p>
            <Balancer>
              You have not made any requests yet for this period, get your API
              key and use one of our SDKs to get started!
            </Balancer>
          </p>
          <Button to={`/project/${project.slug}/api-keys`} inline>
            Get my API key
          </Button>
        </div>
      ) : (
        <>
          <label className={style.dailyRequestSwitch}>
            <SwitchInput value={showDaily} onChange={setShowDaily} />
            Show daily requests
          </label>
          <ResponsiveContainer width="100%" height={200}>
            <ComposedChart
              data={graphData}
              margin={{ top: 5, right: 5, bottom: 0, left: 5 }}
            >
              <defs>
                <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="15%" stopColor="#5785FC" stopOpacity={0.21} />
                  <stop offset="95%" stopColor="#B8C2F9" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="gradientBarFill"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#575efc" />
                  <stop offset="95%" stopColor="#5158ee" />
                </linearGradient>
              </defs>
              <Tooltip
                labelFormatter={(date) =>
                  longDateFormatter.format(new Date(date))
                }
                content={<SimpleTooltip />}
                cursor={{ stroke: '#e7e7e7' }}
              />
              <XAxis
                dataKey="date"
                tickFormatter={(value) =>
                  shortDateFormatter.format(new Date(value))
                }
                interval="preserveStartEnd"
                ticks={[
                  graphData[0]?.date,
                  graphData[graphData.length - 1]?.date,
                ]}
                // tickMargin={8}
                // ticks={graphData
                //   .map((d) => d.date)
                //   .filter((date) => new Date(date).getDate() % 5 === 0)}
                axisLine={false}
                tickLine={false}
              />
              {!showDaily && (
                <Area
                  type="monotone"
                  dataKey="cumulatedRequests"
                  name="Requests in period"
                  stroke="#575efc"
                  strokeWidth={3}
                  strokeLinecap="round"
                  fill="url(#gradientFill)"
                />
              )}
              {showDaily && (
                <Bar
                  dataKey="requests"
                  name="Requests"
                  fill="url(#gradientBarFill)"
                  shape={RoundedBar}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </Card>
  )
}

const Page = () => {
  const { organization } = useCurrentOrgSafe()

  const { data } = useQuery({
    queryKey: ['organization', organization.id, 'usage'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query OrganizationUsage($id: ID!) {
            organization(id: $id) {
              id
              webhooksLimit {
                current
                limit
              }
              flagsLimit {
                current
                limit
              }
              membersLimit {
                current
                limit
              }
              projectsLimit {
                current
                limit
              }
              requestsLimit {
                current
                limit
              }
            }
          }
        `),
        { id: organization.id }
      ),
  })

  return (
    <div className={style.container}>
      <Card variant="outlined">
        <h2>Plan limit</h2>
        <div className={style.meters}>
          <Meter
            title="Members"
            current={data?.organization?.membersLimit.current}
            limit={data?.organization?.membersLimit.limit}
          />
          <Meter
            title="Projects"
            current={data?.organization?.projectsLimit.current}
            limit={data?.organization?.projectsLimit.limit}
          />
          <Meter
            title="Webhooks"
            current={data?.organization?.webhooksLimit.current}
            limit={data?.organization?.webhooksLimit.limit}
          />
          <Meter
            title="Flags"
            current={data?.organization?.flagsLimit.current}
            limit={data?.organization?.flagsLimit.limit}
          />
          <Meter
            title="Requests / month"
            current={data?.organization?.requestsLimit.current}
            limit={data?.organization?.requestsLimit.limit}
          />
        </div>
        <Button
          to="/settings/billing"
          icon={IconLockOpen}
          color="primary"
          inline
        >
          Upgrade plan
        </Button>
      </Card>
      <DailyRequests />
    </div>
  )
}

export const usageRoute: RouteObject = {
  path: 'usage',
  element: <Page />,
}
