/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  getMemoOptions,
  memo,
  RowData,
  Table as TanStackTable,
} from '@tanstack/react-table'

export const getIdOrValue = (value: any) =>
  value && typeof value === 'object' && value.id ? value.id : value

export function getFacetedUniqueValues<TData extends RowData>(): (
  table: TanStackTable<TData>,
  columnId: string
) => () => Map<any, number> {
  return (table, columnId) =>
    memo(
      () => [table.getColumn(columnId)?.getFacetedRowModel()],
      (facetedRowModel) => {
        if (!facetedRowModel) return new Map()

        const facetedUniqueValues = new Map<any, number>()
        const keyToValue = new Map<any, any>()

        for (let i = 0; i < facetedRowModel.flatRows.length; i++) {
          const values =
            facetedRowModel.flatRows[i]!.getUniqueValues<any>(columnId)

          for (const value of values) {
            const key = getIdOrValue(value)
            const keyedValue =
              keyToValue.get(key) ?? keyToValue.set(key, value).get(key)

            if (facetedUniqueValues.has(keyedValue)) {
              facetedUniqueValues.set(
                keyedValue,
                (facetedUniqueValues.get(keyedValue) ?? 0) + 1
              )
            } else {
              facetedUniqueValues.set(keyedValue, 1)
            }
          }
        }

        return facetedUniqueValues
      },
      getMemoOptions(
        table.options,
        'debugTable',
        `getFacetedUniqueValues_${columnId}`
      )
    )
}
