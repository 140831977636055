import { Navigate, Outlet, RouteObject, useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { Table } from '../../../../components/component/Table/Table.tsx'
import { useEffect, useMemo, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { DeepRequired, useForm } from 'react-hook-form'
import {
  FlagStatus,
  FlagTemplate,
  ManageFlagsRole,
  ProjectFlagsQuery,
} from '../../../../gql/graphql.ts'
import { Dropdown } from '../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import {
  IconAlertTriangleFilled,
  IconArchive,
  IconArrowsSplit2,
  IconCopy,
  IconPlus,
  IconRestore,
  IconTrash,
} from '@tabler/icons-react'
import { ShortDate } from '../../../../components/component/ShortDate/ShortDate.tsx'
import { flagRoute } from './flag/Flag.tsx'
import { Badge } from '../../../../components/component/Badge/Badge.tsx'
import {
  useArchiveFlag,
  useDeleteFlag,
  useUnArchiveFlag,
} from '../../../../queries/flags'
import { duplicateFlag } from './flag/Settings.tsx'
import { EmptyStateImage } from '../../../../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import launch from '../../../../assets/empty-state/launch.svg'
import {
  confirm,
  openModal,
} from '../../../../components/component/Modal/Modal.tsx'
import Case from 'case'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import { BigRadioInput } from '../../../../components/input/BigRadioInput/BigRadioInput.tsx'
import betaImg from '../../../../assets/empty-state/beta.svg'
import remoteConfigImg from '../../../../assets/empty-state/remote-config.svg'
import killSwitchIng from '../../../../assets/empty-state/kill-switch.svg'
import scheduleImg from '../../../../assets/empty-state/schedule.svg'
import abTestImg from '../../../../assets/empty-state/ab-test.svg'
import blankImg from '../../../../assets/empty-state/blank.svg'
import { Toolbar } from '../../../../components/component/Toolbar/Toolbar.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { LongTextInput } from '../../../../components/input/LongTextInput/LongTextInput.tsx'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { FormattedList, FormattedNumber } from 'react-intl'
import { Variation } from '../../../../components/component/Variation/Variation.tsx'
import { Tooltip } from '../../../../components/component/Tooltip/Tooltip.tsx'
import { Truncate } from '../../../../components/component/Truncate/Truncate.tsx'
import { SwitchInput } from '../../../../components/input/SwitchInput/SwitchInput.tsx'
import { useFlag } from 'react-tggl-client'
import { SoftRolesSelector } from '../../../../components/input/SoftRolesSelector/SoftRolesSelector.tsx'
import {
  TagsInput,
  useCreateTag,
} from '../../../../components/input/TagsInput/TagsInput.tsx'
import { projectSettingsRoute } from './Settings.tsx'
import { Tabs } from '../../../../components/component/Tabs/Tabs.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import style from './Flags.module.scss'
import { sanitize } from '../../../../utils/string.ts'
import { differenceInDays } from 'date-fns'
import { Code } from '../../../../components/component/Code/Code.tsx'

type Row = DeepRequired<ProjectFlagsQuery>['project']['flags'][0]

export const NewFlagForm = () => {
  const [templateSelection, setTemplateSelection] = useState(true)
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { touchedFields, isSubmitting },
  } = useForm({
    defaultValues: {
      roleIds: [] as string[],
      tagIds: [] as string[],
      template: null as FlagTemplate.PrivateBeta | null,
      description: '',
      slug: '',
      name: '',
    },
  })
  const name = watch('name')
  const template = watch('template')
  const tagIds = watch('tagIds')
  const tagInName = name.match(/^\[([^\]]+)]/)?.[1]?.trim() || null

  useEffect(() => {
    if (!touchedFields.slug) {
      setValue('slug', Case.camel(name))
      clearErrors('slug')
    }
  }, [clearErrors, name, setValue, touchedFields.slug])

  const { project } = useCurrentOrgSafe()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const createTag = useCreateTag()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation createFlag($id: ID!, $data: CreateFlagData!) {
        project(id: $id) {
          createFlag(data: $data) {
            id
            project {
              id
              slug
            }
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: project.id,
      data: {
        name: data.name,
        description: data.description,
        template: data.template as FlagTemplate,
        roleIds: data.roleIds,
        slug: data.slug,
        tagIds: data.tagIds,
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['flags', project.id] })
      toast.success('Flag created')
      navigate(
        `/project/${project.slug}/feature-flags/${data?.project?.createFlag.id}`
      )
    },
  })

  if (templateSelection) {
    return (
      <>
        <InputWrapper
          control={control}
          name="template"
          // @ts-ignore
          component={BigRadioInput}
          options={[
            {
              label: 'Blank template',
              value: FlagTemplate.Blank,
              imageSrc: blankImg,
              description: 'Start from scratch with an empty flag',
            },
            {
              label: 'Private Beta',
              value: FlagTemplate.PrivateBeta,
              imageSrc: betaImg,
              description: 'Handpick a few users to test your new feature',
            },
            {
              label: 'Progressive Rollout',
              value: FlagTemplate.Rollout,
              imageSrc: scheduleImg,
              description:
                'Gradually roll out your feature over a period of time',
            },
            {
              label: 'A/B Test',
              value: FlagTemplate.AbTest,
              imageSrc: abTestImg,
              description:
                'Test two or more variations of your feature against a control group',
            },
            {
              label: 'Remote Config',
              value: FlagTemplate.Config,
              imageSrc: remoteConfigImg,
              description:
                'Control any arbitrary value in your app (color, wording, plan limit...)',
            },
            {
              label: 'Kill Switch',
              value: FlagTemplate.KillSwitch,
              imageSrc: killSwitchIng,
              description:
                'Instantly turn off a feature remotely in case of an emergency or a bug',
            },
          ]}
        />
        <Toolbar alignRight>
          <Button
            color="primary"
            disabled={!template}
            onClick={() => setTemplateSelection(false)}
          >
            Choose template
          </Button>
        </Toolbar>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        control={control}
        name="name"
        component={TextInput}
        label="Name"
        autoFocus
        rules={{ required: true }}
      />
      <AnimatePresence>
        {tagInName && (
          <motion.div
            animate={{ height: 'auto' }}
            initial={{ height: 0 }}
            exit={{ height: 0 }}
            className={style.inFormAlertContainer}
          >
            <div className={style.infoAlert}>
              <span>
                Do you want to add the tag <b>{tagInName}</b>?
              </span>
              <span
                className={style.addTag}
                onClick={() => {
                  setValue('name', name.replace(/^\[([^\]]+)]/, '').trim())
                  const softName = sanitize(tagInName)
                  const existingTag = project.tags.find((tag) => {
                    return softName === sanitize(tag.name)
                  })

                  if (existingTag) {
                    if (tagIds.includes(existingTag.id)) return
                    setValue('tagIds', [...tagIds, existingTag.id])
                  } else {
                    createTag(tagInName).then((id) => {
                      setValue('tagIds', [...tagIds, id])
                    })
                  }
                }}
              >
                Add tag
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <InputWrapper
        control={control}
        name="slug"
        tooltip="A developer friendly name to use inside your code"
        className="monospace"
        component={TextInput}
        label="Key"
        rules={{ required: true }}
      />
      <InputWrapper
        control={control}
        name="tagIds"
        label="Tags"
        component={TagsInput}
      />
      <InputWrapper
        control={control}
        name="description"
        component={LongTextInput}
        label="Description"
      />
      <InputWrapper
        control={control}
        name="roleIds"
        label="Restricted to"
        // @ts-ignore
        component={SoftRolesSelector}
        rules={{ validate: () => true }}
      />
      <Toolbar alignRight>
        <Button type="submit" color="primary" loading={isSubmitting}>
          Create
        </Button>
        <Button
          variant="light"
          onClick={() => setTemplateSelection(true)}
          disabled={isSubmitting}
        >
          Previous
        </Button>
      </Toolbar>
    </form>
  )
}

const UncontrolledSwitch = ({
  value,
  onChange,
}: {
  value: boolean
  onChange: (value: boolean, cancel: () => void) => void
}) => {
  const [checked, setChecked] = useState(value)
  return (
    <SwitchInput
      value={checked}
      onChange={(c) => {
        setChecked(c)
        onChange(c, () => setChecked(!c))
      }}
    />
  )
}

const Flags = () => {
  const { organization, project } = useCurrentOrgSafe()
  const { active: flagLastValuesActive } = useFlag('flagLastValues')

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['flags', project.id],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query projectFlags($id: ID!, $includeArchived: Boolean) {
            project(id: $id) {
              id
              flags(includeArchived: $includeArchived) {
                id
                name
                slug
                description
                active
                createdAt
                archived
                status
                lastEvaluatedAt
                lastUpdatedAt
                contextProperties {
                  id
                  name
                }
                lastWeekVariations {
                  value
                  count
                  variation {
                    name
                    color
                  }
                }
                tags {
                  id
                  color
                  name
                }
                owner {
                  id
                  name
                  email
                }
                currentVariation {
                  __typename
                  ... on CurrentFixedVariation {
                    uselessConditions
                    variation {
                      id
                      name
                      color
                      description
                    }
                  }
                  ... on CurrentBasedOnProperties {
                    variation {
                      id
                      name
                      color
                      description
                    }
                    contextProperties {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `),
        {
          id: project.id,
          includeArchived: true,
        }
      ),
  })

  const archiveFlag = useArchiveFlag()
  const unArchiveFlag = useUnArchiveFlag()
  const deleteFlag = useDeleteFlag()

  const queryClient = useQueryClient()

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.name,
        header: 'Name',
        enableHiding: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>
            <div>
              <em>{row.original.name}</em>{' '}
              {row.original.archived && <Badge color="error">Archived</Badge>}
            </div>
            <small>
              <Truncate text={row.original.description} length={130} />
            </small>
          </div>
        ),
        meta: {
          flex: '1.5 1 200px',
        },
      },
      {
        id: 'archived',
        accessorFn: (row) => row.archived,
        header: 'Archived',
        meta: {
          hidden: true,
          renderFilter: (active) => (active ? 'Archived' : 'Not archived'),
        },
      },
      {
        id: 'tags',
        accessorFn: (row) => row.tags,
        header: 'Tags',
        enableSorting: false,
        getUniqueValues: (row) => row.tags,
        cell: ({ row }) => (
          <Badge.List>
            {row.original.tags.map((tag) => (
              <Badge key={tag.id} color={tag.color}>
                {tag.name}
              </Badge>
            ))}
          </Badge.List>
        ),
        meta: {
          renderFilter: (tag) => (
            <Badge key={tag.id} color={tag.color}>
              {tag.name}
            </Badge>
          ),
        },
      },
      {
        id: 'context',
        accessorFn: (row) => row.contextProperties,
        header: 'Context used',
        enableSorting: false,
        getUniqueValues: (row) => row.contextProperties,
        meta: {
          renderFilter: (prop) => prop.name,
          hidden: true,
        },
      },
      {
        id: 'owner',
        accessorFn: (row) => row.owner,
        header: 'Owner',
        enableSorting: false,
        cell: ({ row }) =>
          row.original.owner?.name ?? row.original.owner?.email,
        meta: {
          renderFilter: (owner) => owner?.name ?? owner?.email,
        },
      },
      {
        id: 'key',
        accessorFn: (row) => row.slug,
        header: 'Key',
        enableColumnFilter: false,
        cell: ({ getValue }) => <Code value={getValue() as string} />,
      },
      {
        id: 'createdAt',
        accessorFn: (row) => row.createdAt,
        header: 'Created',
        sortingFn: 'datetime',
        enableColumnFilter: false,
        cell: ({ row }) => <ShortDate value={row.original.createdAt} ago />,
        meta: {
          flex: '0.2 0 100px',
        },
        minSize: 100,
      } as ColumnDef<Row>,
      ...(flagLastValuesActive
        ? [
            {
              id: 'status',
              header: 'Status',
              accessorFn: (row) => row.status,
              enableSorting: false,
              cell: ({ row }) => {
                if (row.original.status === FlagStatus.New) {
                  return (
                    <Tooltip>
                      <Tooltip.Trigger component={Badge} color="primary">
                        New
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        This flag was created{' '}
                        <ShortDate value={row.original.createdAt} ago /> and can
                        now be used in the code
                      </Tooltip.Content>
                    </Tooltip>
                  )
                }
                if (row.original.status === FlagStatus.Permanent) {
                  return (
                    <Tooltip>
                      <Tooltip.Trigger component={Badge}>
                        Permanent
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        This flag is marked as permanent and was evaluated{' '}
                        <FormattedNumber
                          value={row.original.lastWeekVariations.reduce(
                            (acc, cur) => acc + cur.count,
                            0
                          )}
                        />{' '}
                        times in the past 7 days
                      </Tooltip.Content>
                    </Tooltip>
                  )
                }
                if (row.original.status === FlagStatus.Active) {
                  return (
                    <Tooltip>
                      <Tooltip.Trigger component={Badge} color="success">
                        Active
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        This flag is actively being used
                      </Tooltip.Content>
                    </Tooltip>
                  )
                }
                if (row.original.status === FlagStatus.Stuck) {
                  return (
                    <Tooltip>
                      <Tooltip.Trigger component={Badge} color="warning">
                        Stuck
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        This flag has not changed in{' '}
                        <FormattedNumber
                          value={differenceInDays(
                            new Date(),
                            row.original.lastUpdatedAt
                          )}
                        />{' '}
                        days. You might want to mark it as permanent, or make a
                        decision about its future
                      </Tooltip.Content>
                    </Tooltip>
                  )
                }
                if (row.original.status === FlagStatus.Completed) {
                  return (
                    <Tooltip>
                      <Tooltip.Trigger component={Badge} color="success">
                        Completed
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        This flag has been serving the{' '}
                        {row.original.lastWeekVariations[0]?.variation && (
                          <div
                            style={{
                              display: 'inline-block',
                              width: 8,
                              height: 8,
                              background:
                                row.original.lastWeekVariations[0]?.variation
                                  ?.color,
                              borderRadius: '50%',
                            }}
                          />
                        )}{' '}
                        {row.original.lastWeekVariations[0]?.variation?.name ??
                          'same'}{' '}
                        variation to all users for the past 7 days. It is
                        probably time to remove it from the code.
                      </Tooltip.Content>
                    </Tooltip>
                  )
                }
                if (row.original.status === FlagStatus.Legacy) {
                  return (
                    <Tooltip>
                      <Tooltip.Trigger component={Badge} color="error">
                        Legacy
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        {row.original.lastEvaluatedAt ? (
                          <>
                            This flag hasn't been evaluated for the past{' '}
                            <FormattedNumber
                              value={differenceInDays(
                                new Date(),
                                row.original.lastEvaluatedAt
                              )}
                            />{' '}
                            days. It is probably safe to archive.
                          </>
                        ) : (
                          'This flag was never evaluated and should probably be deleted'
                        )}
                      </Tooltip.Content>
                    </Tooltip>
                  )
                }
                return null
              },
              meta: {
                flex: '0.5 0 120px',
                renderFilter: (status) => {
                  if (status === FlagStatus.New) {
                    return <Badge color="primary">New</Badge>
                  }
                  if (status === FlagStatus.Permanent) {
                    return <Badge>Permanent</Badge>
                  }
                  if (status === FlagStatus.Active) {
                    return <Badge color="success">Active</Badge>
                  }
                  if (status === FlagStatus.Stuck) {
                    return <Badge color="warning">Stuck</Badge>
                  }
                  if (status === FlagStatus.Completed) {
                    return <Badge color="success">Completed</Badge>
                  }
                  if (status === FlagStatus.Legacy) {
                    return <Badge color="error">Legacy</Badge>
                  }
                  return null
                },
              },
              minSize: 120,
            } as ColumnDef<Row>,
          ]
        : [
            {
              id: 'currentValue',
              header: 'Value',
              accessorFn: (row) => row.currentVariation,
              enableSorting: false,
              enableColumnFilter: false,
              cell: ({ row }) => {
                const value = row.original.currentVariation
                return value?.__typename === 'CurrentFixedVariation' ? (
                  <>
                    <Variation variation={value.variation} />{' '}
                    {value.uselessConditions && (
                      <Tooltip>
                        <Tooltip.Trigger
                          component={IconAlertTriangleFilled}
                          size={14}
                          style={{ marginLeft: 8, color: '#e3ae29' }}
                        />
                        <Tooltip.Content>
                          All conditions return the same variation, this might
                          be an error
                        </Tooltip.Content>
                      </Tooltip>
                    )}
                  </>
                ) : value?.__typename === 'CurrentBasedOnProperties' &&
                  value.contextProperties.length <= 3 ? (
                  <div>
                    <Variation variation={value.variation} /> based on{' '}
                    <FormattedList
                      value={value.contextProperties.map(({ name }) => (
                        <span className="dark">{name}</span>
                      ))}
                    />
                  </div>
                ) : value?.__typename === 'CurrentBasedOnProperties' ? (
                  <div>
                    <Variation variation={value.variation} /> based on{' '}
                    <span className="dark">
                      {value.contextProperties.length}
                    </span>{' '}
                    properties
                  </div>
                ) : (
                  <>
                    <IconArrowsSplit2 size={16} /> Conditional
                  </>
                )
              },
              meta: {
                flex: '1 1 150px',
              },
              minSize: 100,
            } as ColumnDef<Row>,
          ]),
      {
        id: 'active',
        header: 'Active',
        accessorFn: (row) => row.active,
        enableSorting: false,
        cell: ({ row }) => (
          <UncontrolledSwitch
            value={row.original.active}
            onChange={async (active, cancel) => {
              if (row.original.archived) {
                await confirm({
                  title: 'Un-archive flag?',
                  description: (
                    <p>
                      This flag is currently archived, do you want to un-archive
                      and activate it now?
                    </p>
                  ),
                  confirmText: 'Un-archive & activate',
                })
                  .then(() =>
                    toast.promise(
                      gqlClient
                        .request(
                          graphql(`
                            mutation activeAndUnarchieveFlag(
                              $id: ID!
                              $active: Boolean!
                            ) {
                              flag(id: $id) {
                                toggleActive(active: $active) {
                                  id
                                }
                                toggleArchive(archive: false) {
                                  id
                                }
                              }
                            }
                          `),
                          { id: row.original.id, active }
                        )
                        .then(() =>
                          queryClient.invalidateQueries({
                            queryKey: ['flags', project.id],
                          })
                        ),
                      {
                        loading: 'Un-archiving flag...',
                        success: 'Flag is now active',
                        error: (error) => {
                          cancel()

                          if (
                            error.response?.errors?.[0]?.extensions?.code ===
                            'TRIAL_ENDED'
                          ) {
                            return 'Your trial has ended, you can no longer activate flags'
                          }
                          return 'Could not toggle flag'
                        },
                      },
                      {
                        id: 'toggle-flag' + row.original.id,
                      }
                    )
                  )
                  .catch(() => cancel())
              } else {
                await confirm({
                  title: 'Toggle flag?',
                  description: (
                    <p>
                      This may affect the production environment, are you sure?
                    </p>
                  ),
                  confirmText: active ? 'Activate' : 'Deactivate',
                })
                  .then(() =>
                    toast.promise(
                      gqlClient
                        .request(
                          graphql(`
                            mutation activeFlag($id: ID!, $active: Boolean!) {
                              flag(id: $id) {
                                toggleActive(active: $active) {
                                  id
                                  active
                                }
                              }
                            }
                          `),
                          { id: row.original.id, active }
                        )
                        .then(() =>
                          queryClient.invalidateQueries({
                            queryKey: ['flags', project.id],
                          })
                        ),
                      {
                        loading: 'Toggling flag...',
                        success: active
                          ? 'Flag is now active'
                          : 'Flag is now inactive',
                        error: (error) => {
                          cancel()

                          if (
                            error.response?.errors?.[0]?.extensions?.code ===
                            'TRIAL_ENDED'
                          ) {
                            return 'Your trial has ended, you can no longer activate flags'
                          }

                          if (
                            error.response?.errors?.[0]?.extensions?.code ===
                            'INSUFFICIENT_ROLE'
                          ) {
                            const reason =
                              error.response?.errors?.[0]?.extensions?.reason

                            if (reason === 'NOT_SHARED') {
                              return 'This flag is not shared with you'
                            } else if (reason === 'READ_ONLY') {
                              return 'You cannot manage flags'
                            } else {
                              return 'You do not have sufficient permission'
                            }
                          }
                          return 'Could not toggle flag'
                        },
                      },
                      {
                        id: 'toggle-flag' + row.original.id,
                      }
                    )
                  )
                  .catch(() => cancel())
              }
            }}
          />
        ),
        meta: {
          flex: '0 0 110px',
          noLink: true,
          renderFilter: (active) => (active ? 'Active' : 'Inactive'),
        },
        minSize: 110,
      },
    ],
    [project.id, queryClient, flagLastValuesActive]
  )

  return (
    <>
      <Table<Row>
        data={(data?.project?.flags as Row[]) ?? []}
        loading={isLoading}
        fetching={isFetching}
        columns={columns}
        unit="flags"
        defaultSorting={[{ id: 'name', desc: false }]}
        rowTo={({ id }) =>
          `/project/${project.slug}/feature-flags/${id}/conditions`
        }
        defaultColumnVisibility={{ key: false, createdAt: false }}
        defaultColumnFilters={[{ id: 'archived', value: [false] }]}
        moreActions={
          organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
            ? ({ row }) =>
                row.original.archived ? (
                  <>
                    <Dropdown.Item
                      label="Un-archive"
                      icon={IconRestore}
                      onClick={() => unArchiveFlag(row.original.id)}
                    />
                    <hr />
                    <Dropdown.Item
                      label="Permanently delete"
                      icon={IconTrash}
                      danger
                      onClick={() => deleteFlag(row.original.id)}
                    />
                  </>
                ) : (
                  <>
                    <Dropdown.Item
                      label="Make a copy"
                      icon={IconCopy}
                      onClick={() => duplicateFlag(row.original.id)}
                    />
                    <hr />
                    <Dropdown.Item
                      label="Archive"
                      icon={IconArchive}
                      danger
                      onClick={() => archiveFlag(row.original.id)}
                      tooltip="Can be un-archieved later"
                    />
                  </>
                )
            : undefined
        }
        onCreate={
          organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
            ? () =>
                openModal({
                  title: 'New flag',
                  content: <NewFlagForm />,
                })
            : undefined
        }
        createButtonText="New flag"
        emptyState={
          <EmptyStateImage
            imageSrc={launch}
            title="You have no flags yet"
            text={
              <p>
                Flags lets you turn features on and off, perform AB tests,
                control your app remotely, and much more!
              </p>
            }
            ctaIcon={IconPlus}
            ctaOnClick={() =>
              openModal({
                title: 'New flag',
                content: <NewFlagForm />,
              })
            }
            cta={
              organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
                ? 'New flag'
                : undefined
            }
          />
        }
      />
    </>
  )
}

const ContextHeader = () => {
  return (
    <>
      <h1>Feature flags</h1>
      <Tabs
        tabs={[
          {
            label: 'Flags',
            to: 'flags',
          },
          {
            label: 'Settings',
            to: 'settings',
          },
        ]}
      />
      <Outlet />
    </>
  )
}

export const flagsRoute: RouteObject = {
  path: 'feature-flags',
  element: <Outlet />,
  children: [
    flagRoute,
    {
      path: '',
      element: <ContextHeader />,
      children: [
        projectSettingsRoute,
        {
          path: 'flags',
          element: <Flags />,
        },
        {
          path: '',
          element: <Navigate to="flags" />,
        },
      ],
    },
  ],
}
