import { Outlet, RouteObject, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { gqlClient } from '../../../../../auth'
import { graphql } from '../../../../../gql'
import { Table } from '../../../../../components/component/Table/Table.tsx'
import { useEffect, useMemo, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import {
  ChangeRequestRequirement,
  FlagChange,
  FlagHistoryQuery,
  ReviewsRole,
  RollbackMutationVariables,
} from '../../../../../gql/graphql.ts'
import { DeepRequired } from 'react-hook-form'
import { ShortDate } from '../../../../../components/component/ShortDate/ShortDate.tsx'
import style from './History.module.scss'
import { Card } from '../../../../../components/component/Card/Card.tsx'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Diff } from '../../../../../components/component/Diff/Diff.tsx'
import { Tabs } from '../../../../../components/component/Tabs/Tabs.tsx'
import { Toolbar } from '../../../../../components/component/Toolbar/Toolbar.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import { IconHistory } from '@tabler/icons-react'
import {
  openModal,
  useModalContext,
} from '../../../../../components/component/Modal/Modal.tsx'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import { Checkbox } from '../../../../../components/input/Checkbox/Checkbox.tsx'
import { toast } from 'react-hot-toast'

type Row = DeepRequired<FlagHistoryQuery>['flag']['history'][0]

export const FlagChanges = ({ changes }: { changes: FlagChange[] }) => {
  if (!changes.length) {
    return (
      <Card>
        <p>No changes</p>
      </Card>
    )
  }
  return changes.map((change, i) => {
    if (change.__typename === 'FlagCreated') {
      return (
        <Card key={i}>
          <h2>Flag created</h2>
          <p>Flag was created</p>
        </Card>
      )
    }

    if (change.__typename === 'FlagActivated') {
      if (!change.oldActive && change.newActive) {
        return (
          <Card key={i}>
            <h2>Flag activated</h2>
            <p>
              Flag went from <span className={style.old}>inactive</span> to{' '}
              <span className={style.new}>active</span>
            </p>
          </Card>
        )
      } else {
        return (
          <Card key={i}>
            <h2>Flag de-activated</h2>
            <p>
              Flag went from <span className={style.old}>active</span> to{' '}
              <span className={style.new}>inactive</span>
            </p>
          </Card>
        )
      }
    }

    if (change.__typename === 'FlagPermanentChanged') {
      if (change.newPermanent) {
        return (
          <Card key={i}>
            <h2>Flag marked as permanent</h2>
            <p>
              Flag was marked as <span className={style.new}>permanent</span>,
              meaning it is meant to be long lasting.
            </p>
          </Card>
        )
      } else {
        return (
          <Card key={i}>
            <h2>Flag marked as temporary</h2>
            <p>
              Flag was marked as <span className={style.new}>temporary</span>,
              meaning it is meant to be deleted in the near future.
            </p>
          </Card>
        )
      }
    }

    if (change.__typename === 'FlagOwnerChanged') {
      if (!change.oldOwner) {
        return (
          <Card key={i}>
            <h2>Flag owner assigned</h2>
            <p>
              Flag was assign{' '}
              <span className={style.new}>{change.newOwner}</span> as owner.
            </p>
          </Card>
        )
      } else if (!change.newOwner) {
        return (
          <Card key={i}>
            <h2>Flag owner removed</h2>
            <p>
              Flag owner <span className={style.old}>{change.oldOwner}</span>{' '}
              was removed.
            </p>
          </Card>
        )
      } else {
        return (
          <Card key={i}>
            <h2>Flag owner changed</h2>
            <p>
              Flag owner changed from{' '}
              <span className={style.old}>{change.oldOwner}</span> to{' '}
              <span className={style.new}>{change.newOwner}</span>.
            </p>
          </Card>
        )
      }
    }

    if (change.__typename === 'FlagArchived') {
      if (change.oldArchived && !change.newArchived) {
        return (
          <Card key={i}>
            <h2>Flag un-archived</h2>
            <p>
              Flag went from <span className={style.old}>archived</span> to{' '}
              <span className={style.new}>visible</span>
            </p>
          </Card>
        )
      } else {
        return (
          <Card key={i}>
            <h2>Flag archived</h2>
            <p>
              Flag went from <span className={style.old}>visible</span> to{' '}
              <span className={style.new}>archived</span>
            </p>
          </Card>
        )
      }
    }

    if (change.__typename === 'FlagDefaultVariationChanged') {
      return (
        <Card key={i}>
          <h2>Fallback variation changed</h2>
          <p>
            The fallback variation changed from{' '}
            <span className={style.old}>{change.oldVariation.name}</span> to{' '}
            <span className={style.new}>{change.newVariation.name}</span>
          </p>
        </Card>
      )
    }

    if (change.__typename === 'FlagNameChanged') {
      return (
        <Card key={i}>
          <h2>Flag renamed</h2>
          <p>
            Flag was renamed from{' '}
            <span className={style.old}>{change.oldName}</span> to{' '}
            <span className={style.new}>{change.newName}</span>
          </p>
        </Card>
      )
    }

    if (change.__typename === 'FlagDescriptionChanged') {
      if (change.oldDescription && change.newDescription) {
        return (
          <Card key={i}>
            <h2>Flag description changed</h2>
            <p>Before</p>
            <p className={style.old}>{change.oldDescription}</p>
            <p>After</p>
            <p className={style.new}>{change.newDescription}</p>
          </Card>
        )
      } else if (!change.oldDescription && change.newDescription) {
        return (
          <Card key={i}>
            <h2>Flag description added</h2>
            <p className={style.new}>{change.newDescription}</p>
          </Card>
        )
      } else {
        return (
          <Card key={i}>
            <h2>Flag description removed</h2>
            <p className={style.old}>{change.oldDescription}</p>
          </Card>
        )
      }
    }

    if (change.__typename === 'FlagSlugChanged') {
      return (
        <Card key={i}>
          <h2>Flag key changed</h2>
          <p>
            Flag key changed from{' '}
            <span className={style.old}>{change.oldSlug}</span> to{' '}
            <span className={style.new}>{change.newSlug}</span>
          </p>
        </Card>
      )
    }

    if (change.__typename === 'FlagSharingChanged') {
      if (!change.oldShareWithEveryone && change.newShareWithEveryone) {
        return (
          <Card key={i}>
            <h2>Flag is now shared with everyone</h2>
            <p>
              Flag was shared with{' '}
              <span className={style.old}>{change.oldRoles.join(', ')}</span>{' '}
              and is now shared with <span className={style.new}>everyone</span>
            </p>
          </Card>
        )
      }
      if (change.oldShareWithEveryone && !change.newShareWithEveryone) {
        return (
          <Card key={i}>
            <h2>Flag access is now restricted</h2>
            <p>
              Flag was shared with <span className={style.old}>everyone</span>{' '}
              and is now shared with{' '}
              <span className={style.new}>{change.newRoles.join(', ')}</span>
            </p>
          </Card>
        )
      }

      return (
        <Card key={i}>
          <h2>Flag access changed</h2>
          <p>
            Flag was shared with{' '}
            <span className={style.old}>{change.oldRoles.join(', ')}</span> and
            is now shared with{' '}
            <span className={style.new}>{change.newRoles.join(', ')}</span>
          </p>
        </Card>
      )
    }

    if (change.__typename === 'FlagTagsChanged') {
      return (
        <Card key={i}>
          <h2>Flag tags changed</h2>
          {change.removedTags.length > 0 && (
            <p>
              {change.removedTags.length > 1 ? 'Tags' : 'Tag'}{' '}
              <span className={style.old}>
                {change.removedTags.map(({ name }) => name).join(', ')}
              </span>{' '}
              {change.removedTags.length > 1 ? 'were' : 'was'} removed.
            </p>
          )}
          {change.addedTags.length > 0 && (
            <p>
              {change.addedTags.length > 1 ? 'Tags' : 'Tag'}{' '}
              <span className={style.new}>
                {change.addedTags.map(({ name }) => name).join(', ')}
              </span>{' '}
              {change.addedTags.length > 1 ? 'were' : 'was'} added.
            </p>
          )}
        </Card>
      )
    }

    if (change.__typename === 'FlagVariationCreated') {
      return (
        <Card key={i}>
          <h2>Variation created</h2>
          <p>
            Variation <span className={style.new}>{change.variation.name}</span>{' '}
            was created with value:{' '}
          </p>
          <SyntaxHighlighter
            language="json"
            style={dracula}
            children={JSON.stringify(change.variation.value, null, 2)}
          />
        </Card>
      )
    }

    if (change.__typename === 'FlagVariationDeleted') {
      return (
        <Card key={i}>
          <h2>Variation deleted</h2>
          <p>
            Variation <span className={style.old}>{change.variation.name}</span>{' '}
            was deleted
          </p>
        </Card>
      )
    }

    if (change.__typename === 'FlagVariationUpdated') {
      return (
        <Card key={i}>
          <h2>Variation updated</h2>
          <p>
            Variation <b>{change.newVariation.name}</b> was updated:
          </p>
          <Diff before={change.oldVariation} after={change.newVariation} />
        </Card>
      )
    }

    if (change.__typename === 'FlagConditionCreated') {
      return (
        <Card key={i}>
          <h2>Condition created</h2>
          <p>A new condition was created:</p>
          <Diff
            before={''}
            after={change.condition.codeLike}
            stringify={String}
          />
        </Card>
      )
    }

    if (change.__typename === 'FlagConditionDeleted') {
      return (
        <Card key={i}>
          <h2>Condition deleted</h2>
          {change.condition.name && (
            <p>
              Condition{' '}
              <span className={style.old}>{change.condition.name}</span> was
              deleted:
            </p>
          )}
          {!change.condition.name && <p>A condition was deleted:</p>}
          <Diff
            before={change.condition.codeLike}
            after={''}
            stringify={String}
          />
        </Card>
      )
    }

    if (change.__typename === 'FlagConditionUpdated') {
      const { codeLike: oldCodeLike, ...oldCondition } = change.oldCondition
      const { codeLike: newCodeLike, ...newCondition } = change.newCondition

      return (
        <Card key={i}>
          <h2>Condition updated</h2>
          {change.newCondition.name && (
            <p>
              Condition <b>{change.newCondition.name}</b> was updated:
            </p>
          )}
          {!change.newCondition.name && <p>A condition was updated:</p>}

          {(oldCondition.name !== newCondition.name ||
            oldCondition.order !== newCondition.order) && (
            <Diff before={oldCondition} after={newCondition} />
          )}
          <Diff before={oldCodeLike} after={newCodeLike} stringify={String} />
        </Card>
      )
    }
  })
}

const CreatedBy = ({
  author,
  id,
}: {
  author: Row['createdBy']
  id: string
}) => {
  const { historyId } = useParams()

  return (
    <span aria-expanded={id === historyId ? 'true' : undefined}>
      {author.name ?? author.email}
    </span>
  )
}

const useFlagHistory = (flagId: string) => {
  return useQuery({
    queryKey: ['flag', flagId, 'history'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query flagHistory($id: ID!) {
            flag(id: $id) {
              id
              history {
                id
                createdAt
                createdBy {
                  id
                  name
                  email
                }
              }
            }
          }
        `),
        { id: flagId as string }
      ),
    staleTime: 10000,
  })
}

const Page = () => {
  const { flagId, historyId } = useParams()

  const { data, isLoading, isFetching } = useFlagHistory(flagId as string)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isFetching && !historyId) {
      navigate(`${data?.flag?.history[0]?.id}`)
    }
  }, [data?.flag?.history, historyId, isFetching, navigate])

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.createdBy,
        header: 'Author',
        cell: ({ row }) => (
          <CreatedBy author={row.original.createdBy} id={row.id} />
        ),
        enableSorting: false,
        meta: {
          renderFilter: (value) => value.name ?? value.email,
        },
      },
      {
        id: 'date',
        accessorFn: (row) => row.createdAt,
        sortingFn: 'datetime',
        header: 'Date',
        enableColumnFilter: false,
        cell: ({ row }) => <ShortDate value={row.original.createdAt} ago />,
      },
    ],
    []
  )

  return (
    <div className={style.container}>
      <aside>
        <Table<Row>
          data={(data?.flag?.history as Row[]) ?? []}
          columns={columns}
          loading={isLoading}
          fetching={isFetching}
          localState
          defaultSorting={[{ id: 'date', desc: true }]}
          rowTo={({ id }) => id}
          noToolbar
        />
      </aside>
      <main>
        <Outlet />
      </main>
    </div>
  )
}

const Rollback = ({
  codeLike,
  id,
  flagId,
}: {
  codeLike: string
  id: string
  flagId: string
}) => {
  const modal = useModalContext()
  const { project, organization } = useCurrentOrgSafe()
  const [immediatly, setImmediately] = useState(!organization.canMakeReviews)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationFn: (variables: RollbackMutationVariables) =>
      gqlClient.request(
        graphql(`
          mutation rollback(
            $flagId: ID!
            $changeRequest: Boolean!
            $historyId: ID!
          ) {
            flag(id: $flagId) {
              rollback(changeRequest: $changeRequest, historyId: $historyId) {
                changeRequest {
                  id
                }
                history {
                  id
                }
              }
            }
          }
        `),
        variables
      ),
    onError: (error: any) => {
      if (error.response?.errors?.[0]?.extensions?.code === 'NO_CHANGES') {
        toast.success('Nothing to rollback, flag is already like this')
        modal?.close()
      } else {
        toast.error('Failed to rollback flag')
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['flag', flagId] })

      if (data.flag?.rollback.history) {
        navigate(
          `/project/${project.slug}/feature-flags/${flagId}/history/${data.flag.rollback.history.id}`
        )
        toast.success('Flag was rolled back successfully')
      }

      if (data.flag?.rollback.changeRequest) {
        navigate(
          `/project/${project.slug}/feature-flags/${flagId}/reviews/${data.flag.rollback.changeRequest.id}`
        )
        toast.success('A review was created to rollback the flag')
      }

      modal?.close()
    },
  })

  return (
    <>
      <p>After rolling back, the flag will look like this:</p>
      <SyntaxHighlighter language="text" style={dracula} children={codeLike} />
      {organization.canMakeReviews &&
      (organization.myAggregatedRole.reviews === ReviewsRole.Bypass ||
        project.requireChangeRequest === ChangeRequestRequirement.Never) ? (
        <label className={style.checkBox}>
          <Checkbox value={immediatly} onChange={setImmediately} /> Skip the
          review process, rollback immediately
        </label>
      ) : null}
      <Toolbar alignRight style={{ marginTop: 16 }}>
        {immediatly && (
          <Button
            color="primary"
            onClick={() =>
              mutate({ flagId, historyId: id, changeRequest: false })
            }
            loading={isPending}
          >
            Rollback now
          </Button>
        )}
        {!immediatly && (
          <Button
            color="success"
            onClick={() =>
              mutate({ flagId, historyId: id, changeRequest: true })
            }
            loading={isPending}
          >
            Request change
          </Button>
        )}
        <Button variant="light" onClick={() => modal?.close()}>
          Cancel
        </Button>
      </Toolbar>
    </>
  )
}

const HistoryDiff = () => {
  const { historyId, flagId } = useParams()

  const { data } = useQuery({
    queryKey: ['flagHistoryStep', historyId],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query flagHistoryStep($id: ID!) {
            flagHistory(id: $id) {
              id
              createdAt
              createdBy {
                id
                name
                email
              }
              value
              codeLikeAfter
              codeLikeBefore
              changeRequest {
                id
                createdBy {
                  name
                  email
                }
              }
              changes {
                __typename
                ... on FlagCreated {
                  created
                }
                ... on FlagActivated {
                  newActive
                  oldActive
                }
                ... on FlagArchived {
                  newArchived
                  oldArchived
                }
                ... on FlagPermanentChanged {
                  newPermanent
                  oldPermanent
                }
                ... on FlagOwnerChanged {
                  oldOwner
                  newOwner
                }
                ... on FlagTagsChanged {
                  addedTags {
                    name
                  }
                  removedTags {
                    name
                  }
                }
                ... on FlagDefaultVariationChanged {
                  oldVariation {
                    name
                    color
                    active
                    value
                  }
                  newVariation {
                    name
                    color
                    active
                    value
                  }
                }
                ... on FlagNameChanged {
                  newName
                  oldName
                }
                ... on FlagDescriptionChanged {
                  newDescription
                  oldDescription
                }
                ... on FlagSlugChanged {
                  newSlug
                  oldSlug
                }
                ... on FlagSharingChanged {
                  newShareWithEveryone
                  oldShareWithEveryone
                  oldRoles
                  newRoles
                }
                ... on FlagVariationCreated {
                  variation {
                    name
                    value
                  }
                }
                ... on FlagVariationDeleted {
                  variation {
                    name
                  }
                }
                ... on FlagVariationUpdated {
                  oldVariation {
                    name
                    color
                    active
                    value
                    description
                    order
                  }
                  newVariation {
                    name
                    color
                    active
                    value
                    description
                    order
                  }
                }
                ... on FlagConditionCreated {
                  condition {
                    name
                    codeLike
                  }
                }
                ... on FlagConditionDeleted {
                  condition {
                    name
                    codeLike
                  }
                }
                ... on FlagConditionUpdated {
                  oldCondition {
                    name
                    order
                    codeLike
                  }
                  newCondition {
                    name
                    order
                    codeLike
                  }
                }
              }
            }
          }
        `),
        { id: historyId as string }
      ),
    staleTime: 10000,
  })

  const flagHistory = data?.flagHistory
  const [currentTab, setCurrentTab] = useState('changes')

  if (!flagHistory) {
    return null
  }

  return (
    <>
      <Card>
        <h2>
          Author
          <span style={{ float: 'right' }}>
            <ShortDate value={flagHistory.createdAt ?? 0} ago />
          </span>
        </h2>
        {flagHistory.changeRequest ? (
          <p>
            Change requested by{' '}
            <b>
              {flagHistory.changeRequest.createdBy.name ??
                flagHistory.changeRequest.createdBy.email}
            </b>
            <br />
            Applyied by{' '}
            <b>{flagHistory.createdBy.name ?? flagHistory.createdBy.email}</b>
          </p>
        ) : (
          <p>
            Change made by{' '}
            <b>{flagHistory.createdBy.name ?? flagHistory.createdBy.email}</b>
          </p>
        )}
        <Toolbar>
          <Button
            icon={IconHistory}
            onClick={() =>
              openModal({
                title: 'Rollback to this version?',
                size: 'm',
                content: (
                  <Rollback
                    codeLike={flagHistory?.codeLikeAfter}
                    id={flagHistory?.id}
                    flagId={flagId as string}
                  />
                ),
              })
            }
          >
            Rollback to here
          </Button>
          {flagHistory.changeRequest && (
            <Button to={`../../reviews/${flagHistory.changeRequest.id}`}>
              Go to review
            </Button>
          )}
        </Toolbar>
      </Card>
      <Tabs
        tabs={[
          { label: 'Changes', to: 'changes' },
          ...(flagHistory.codeLikeBefore
            ? [{ label: 'Before changes', to: 'before' }]
            : []),
          { label: 'After changes', to: 'after' },
        ]}
        current={currentTab}
        onTabChange={(name) => setCurrentTab(name)}
      />
      {currentTab === 'changes' && (
        // @ts-ignore
        <FlagChanges changes={flagHistory.changes} />
      )}
      {currentTab === 'before' && flagHistory.codeLikeBefore && (
        <Card>
          <h2>Before change</h2>
          <p>This is what the flag looked like before the change.</p>
          <SyntaxHighlighter
            language="text"
            style={dracula}
            children={flagHistory.codeLikeBefore as string}
          />
        </Card>
      )}
      {currentTab === 'after' && (
        <Card>
          <h2>After change</h2>
          <p>This is what the flag looked like after the change.</p>
          <SyntaxHighlighter
            language="text"
            style={dracula}
            children={flagHistory.codeLikeAfter}
          />
        </Card>
      )}
    </>
  )
}

export const historyRoute: RouteObject = {
  path: 'history',
  element: <Page />,
  children: [
    {
      path: ':historyId',
      element: <HistoryDiff />,
    },
  ],
}
