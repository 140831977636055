import { RouteObject, useParams } from 'react-router-dom'
import '../../../../../components/input/ConditionsInput/ConditionsInput.scss'
import {
  FlagEvaluationDuration,
  FlagEvaluationGroupBy,
} from '../../../../../gql/graphql.ts'
import { useMemo } from 'react'
import { graphql } from '../../../../../gql'
import { useQuery } from '@tanstack/react-query'
import { SelectInput } from '../../../../../components/input/SelectInput/SelectInput.tsx'
import { gqlClient } from '../../../../../auth'
import { MonitoringChart } from '../../../../../components/component/MonitoringChart/MonitoringChart.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import { IconLockOpen } from '@tabler/icons-react'
import { Card } from '../../../../../components/component/Card/Card.tsx'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import blurredMonitoring from './blurred-monitoring.webp'
import { Toolbar } from '../../../../../components/component/Toolbar/Toolbar.tsx'
import { FormattedNumber } from 'react-intl'
import style from './Monitoring.module.scss'
import {
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'
import {
  MonitoringTooltip,
  PieChartTooltip,
} from '../../../../../components/component/MonitoringChart/MonitoringTooltip.tsx'
import Color from 'color'
import { useSearchParam } from '../../../../../hooks/useSearchParam.ts'

const timeFormatter = Intl.DateTimeFormat('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
})

const dateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
})

const Page = () => {
  const { flagId } = useParams()
  const [timeFrame, setTimeFrame] = useSearchParam<FlagEvaluationDuration>(
    't',
    {
      defaultValue: FlagEvaluationDuration.H2,
      parse: (str) => str as FlagEvaluationDuration,
      format: (value) => value,
    }
  )
  const [groupBy, setGroupBy] = useSearchParam<FlagEvaluationGroupBy>('g', {
    defaultValue: FlagEvaluationGroupBy.Variation,
    parse: (str) => str as FlagEvaluationGroupBy,
    format: (value) => value,
  })

  const { organization } = useCurrentOrgSafe()

  const { data, error } = useQuery({
    queryKey: ['flag', flagId, 'evaluationsByClients', timeFrame, groupBy],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query evaluationsByClients(
            $id: ID!
            $duration: FlagEvaluationDuration!
            $groupBy: FlagEvaluationGroupBy!
          ) {
            flag(id: $id) {
              id
              evaluationsHistory(duration: $duration, groupBy: $groupBy) {
                series {
                  name
                  key
                  color
                }
                data
              }
            }
          }
        `),
        { id: flagId as string, duration: timeFrame, groupBy }
      ),
    staleTime: 10000,
    retry: false,
  })

  const seriesData = useMemo(
    () => data?.flag?.evaluationsHistory.data ?? [],
    [data?.flag?.evaluationsHistory.data]
  )

  const series = useMemo(() => {
    return (
      data?.flag?.evaluationsHistory.series.map((s, i, arr) => {
        const defaultColor =
          groupBy === FlagEvaluationGroupBy.Variation ? '#7e7e7e' : '#4845dc'
        const defaultToColor =
          groupBy === FlagEvaluationGroupBy.Variation ? '#c4c4c4' : '#1482ec'
        return {
          name: s.name,
          key: s.key,
          value: seriesData.reduce((acc, d) => acc + d[s.key], 0),
          color: Color(
            s.color ??
              (arr.length > 1
                ? Color(defaultColor)
                    .mix(Color(defaultToColor), i / (arr.length - 1))
                    .toString()
                : defaultColor)
          )
            .mix(Color('white'), 0.2)
            .toString(),
        }
      }) ?? []
    )
  }, [seriesData, data?.flag?.evaluationsHistory.series, groupBy])

  return (
    <>
      <Toolbar style={{ marginBottom: 20 }}>
        <SelectInput
          value={timeFrame}
          onChange={setTimeFrame}
          autoWidth
          options={[
            { value: FlagEvaluationDuration.H2, label: 'Past 2 hours' },
            { value: FlagEvaluationDuration.D1, label: 'Past 24 hours' },
            { value: FlagEvaluationDuration.D7, label: 'Past week' },
            { value: FlagEvaluationDuration.M1, label: 'Past month' },
            { value: FlagEvaluationDuration.Y1, label: 'Past year' },
          ]}
        />
        <SelectInput
          value={groupBy}
          onChange={setGroupBy}
          autoWidth
          options={[
            { value: FlagEvaluationGroupBy.Variation, label: 'Variations' },
            { value: FlagEvaluationGroupBy.Client, label: 'Clients' },
          ]}
        />
      </Toolbar>
      <div>
        {(error as any)?.response?.errors?.[0]?.extensions?.code ===
        'PLAN_LIMIT' ? (
          <div
            style={{
              position: 'relative',
              aspectRatio: '1122/467',
              boxSizing: 'border-box',
              padding: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={blurredMonitoring}
              style={{
                filter: 'blur(10px)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                zIndex: -1,
              }}
            />
            <Card variant="outlined">
              <h2>Your current plan is limited</h2>
              <p>
                Your current plan only supports monitoring for the past{' '}
                <b>
                  {organization.monitoringDaysLimit === 1
                    ? '24 hours'
                    : `${organization.monitoringDaysLimit} days`}
                </b>
                . Upgrade now to see data up to a year for this flag.
              </p>
              <p style={{ marginBottom: 20 }}>
                Long term monitoring can help you manage technical debt by
                tracking which flags are used and which are not, and
                automatically alert you when actions need to be taken.
              </p>
              <Button
                icon={IconLockOpen}
                color="primary"
                to="/settings/billing"
                inline
              >
                Upgrade plan
              </Button>
            </Card>
          </div>
        ) : (
          <>
            <MonitoringChart data={seriesData} series={series} />
            <div className={style.grid}>
              <Card>
                <h2>Events</h2>
                <div className={style.metric}>
                  <FormattedNumber
                    value={series.reduce((acc, s) => acc + s.value, 0)}
                  />
                </div>
                <p className={style.metricDescription}>
                  Number of times the flag was evaluated during the period
                </p>
              </Card>
              <Card style={{ paddingBottom: 0 }}>
                <h2>Distribution</h2>
                <p className={style.metricDescription}>
                  Average variations of the flag during the period
                </p>
                <ResponsiveContainer width="100%" aspect={2}>
                  <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                    <Tooltip content={<PieChartTooltip series={series} />} />
                    <Pie
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      data={series}
                      cx="50%"
                      cy="100%"
                      fill="#8884d8"
                      outerRadius="190%"
                      innerRadius="120%"
                      paddingAngle={5}
                      stroke="false"
                    >
                      {series.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color ?? undefined}
                          stroke={entry.color ?? undefined}
                          strokeWidth={6}
                          strokeLinejoin="round"
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Card>
              <Card>
                <h2>Evolution</h2>
                <p className={style.metricDescription}>
                  Evolution of the distribution during the period
                </p>
                <ResponsiveContainer width="100%" height={100}>
                  <AreaChart
                    data={seriesData.map((d) => {
                      const sum = series.reduce((acc, s) => acc + d[s.key], 0)
                      const r = { ...d }

                      if (!sum) {
                        for (const s of series) {
                          r[s.key] = null
                        }
                      }

                      return r
                    })}
                    stackOffset="expand"
                  >
                    <defs>
                      {series.map((s) => (
                        <linearGradient
                          id={`gradientFillSerie${s.key}`}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                          key={s.key}
                        >
                          <stop
                            offset="15%"
                            stopColor={s.color}
                            stopOpacity={0.3}
                          />
                          <stop
                            offset="95%"
                            stopColor={s.color}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      ))}
                    </defs>
                    <Tooltip
                      content={<MonitoringTooltip series={series} percent />}
                      cursor={{ stroke: '#e7e7e7' }}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => {
                        const domainSizeHours = seriesData.length
                          ? (seriesData[seriesData.length - 1].next -
                              seriesData[0].date) /
                            60 /
                            60
                          : 0

                        return (
                          domainSizeHours <= 24 ? timeFormatter : dateFormatter
                        ).format(new Date(value * 1000))
                      }}
                      ticks={[
                        seriesData[0]?.date,
                        seriesData[seriesData.length - 1]?.date,
                      ]}
                      interval="preserveStartEnd"
                      axisLine={false}
                      tickLine={false}
                    />
                    {series.map((s) => (
                      <Area
                        key={s.key}
                        type="monotone"
                        dataKey={s.key}
                        stackId="1"
                        name={s.name}
                        stroke={s.color}
                        strokeWidth={3}
                        strokeLinecap="round"
                        connectNulls
                        fill={`url(#gradientFillSerie${s.key})`}
                      />
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </Card>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export const monitoringRoute: RouteObject = {
  path: 'monitoring',
  element: <Page />,
}
