import { ColumnFiltersState } from '@tanstack/react-table'

const separator = ' '

export const serializeFilters = (filters: ColumnFiltersState): string => {
  try {
    filters.sort((a, b) => a.id.localeCompare(b.id))

    let result = ''

    result += filters
      .map((filter) => {
        if (filter.id.includes(separator)) {
          throw new Error(`Filter id cannot contain "${separator}"`)
        }

        if (!Array.isArray(filter.value)) {
          throw new Error(
            `Filter value must be an array, got: ${typeof filter.value}`
          )
        }

        return (
          filter.id +
          separator +
          filter.value
            .map((value) => {
              if (value === null) {
                return 'n'
              }
              if (value === true) {
                return 't'
              }
              if (value === false) {
                return 'f'
              }
              if (typeof value === 'number') {
                if (Math.round(value) !== value) {
                  throw new Error(`Filter value must be an integer, got: float`)
                }
                return 'i' + value
              }
              if (typeof value === 'string') {
                if (value.includes(separator)) {
                  throw new Error(`Filter value cannot contain "${separator}"`)
                }
                return 's' + value + separator
              }
              throw new Error(`Unsupported filter value type: ${typeof value}`)
            })
            .sort()
            .join('')
        )
      })
      .join(separator)

    return result
  } catch (error) {
    return JSON.stringify(
      filters.reduce((acc, cur) => {
        acc[cur.id] = cur.value as any[]
        return acc
      }, {} as Record<string, any[]>)
    )
  }
}

export const deserializeFilters = (serialized: string): ColumnFiltersState => {
  try {
    const result: ColumnFiltersState = []
    let remaining = serialized

    while (remaining) {
      const index = remaining.indexOf(separator)

      if (index === -1) {
        throw new Error(`Invalid filter serialization: ${serialized}`)
      }

      const id = remaining.slice(0, index)
      remaining = remaining.slice(index + 1)

      const values: any[] = []

      while (['n', 't', 'f', 'i', 's'].includes(remaining[0])) {
        const type = remaining[0]
        remaining = remaining.slice(1)

        if (type === 'n') {
          values.push(null)
        } else if (type === 't') {
          values.push(true)
        } else if (type === 'f') {
          values.push(false)
        } else if (type === 'i') {
          const value = remaining.match(/^([0-9]+)/)?.[1]

          if (!value) {
            throw new Error(`Invalid filter serialization: ${serialized}`)
          }

          remaining = remaining.slice(value.length)

          values.push(Number(value))
        } else if (type === 's') {
          const index = remaining.indexOf(separator)
          if (index === -1) {
            throw new Error(`Invalid filter serialization: ${serialized}`)
          }
          values.push(remaining.slice(0, index))
          remaining = remaining.slice(index + 1)
        }
      }

      result.push({ id, value: values })

      if (remaining[0] === separator) {
        remaining = remaining.slice(1)
      }
    }

    return result
  } catch (error) {
    return Object.entries(JSON.parse(serialized)).map(([id, value]) => ({
      id,
      value,
    }))
  }
}
