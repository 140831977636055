import { forwardRef, ReactNode, MouseEvent } from 'react'
import { TablerIconsProps } from '@tabler/icons-react'
import './IconButton.scss'
import cx from 'classnames'

export const IconButton = forwardRef<
  HTMLButtonElement,
  {
    icon: (props: TablerIconsProps) => ReactNode
    color?: 'primary' | 'default'
    onClick?: (event: MouseEvent) => void
    disabled?: boolean
  }
>(({ icon: Icon, onClick, disabled, color, ...props }, ref) => {
  return (
    <button
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()

        if (!disabled) {
          onClick?.(e)
        }
      }}
      aria-disabled={disabled ? 'true' : undefined}
      className={cx(
        'icon-btn',
        disabled && 'disabled',
        color === 'primary' && 'primary'
      )}
      ref={ref}
      type="button"
    >
      <Icon size={14} stroke={1.8} />
    </button>
  )
})
