import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StrictMode, useEffect } from 'react'
import * as ReactDOM from 'react-dom/client'
import { toast, ToastBar, Toaster } from 'react-hot-toast'
import './index.scss'
import { RouterProvider } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import rawMessages from './intl/messages.json'
import { IconCheck, IconLoader2, IconX } from '@tabler/icons-react'
import { IconButton } from './components/component/IconButton/IconButton.tsx'
import { router } from './router.tsx'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { TgglClient, TgglProvider, useTggl } from 'react-tggl-client'
import { useCurrentOrg, useUser } from './auth'
import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://52648820517cfba9899fdf8c27000a10@o4507969823899648.ingest.de.sentry.io/4507969829929040',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.5,
    // tracePropagationTargets: ["localhost", /^https:\/\/api\.tggl\.io/],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

const messages = flatten(rawMessages) as Record<string, string>

const queryClient = new QueryClient()
const client = new TgglClient('SABVMfsNoIWAlynKpGhxE6TK_eWnXgoKeoZu8KckEV4')

const UpdateTgglContext = () => {
  const user = useUser()
  const { organization } = useCurrentOrg()
  const { setContext } = useTggl()

  useEffect(() => {
    setContext({
      organizationId: organization?.id,
      organizationName: organization?.name,
      userId: user?.id,
      userName: user?.name ?? undefined,
      email: user?.email,
    })
  }, [
    setContext,
    user?.id,
    user?.email,
    organization?.id,
    organization?.name,
    user?.name,
  ])

  return null
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <GoogleOAuthProvider clientId="463120787053-aquitghpsdfk8qlld0s6etts9pus2jrc.apps.googleusercontent.com">
    <StrictMode>
      <TgglProvider client={client}>
        <UpdateTgglContext />
        <IntlProvider locale="en-GB" messages={messages}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <Toaster
              position="bottom-left"
              toastOptions={{
                duration: 5000,
                className: 'toast',
                success: {
                  className: 'toast success',
                },
                error: {
                  className: 'toast error',
                },
                loading: {
                  className: 'toast loading',
                },
              }}
            >
              {(t) => (
                <ToastBar toast={t} style={t.style} position={t.position}>
                  {({ message }) => (
                    <>
                      {t.type === 'success' && (
                        <div className="icon">
                          <IconCheck size={18} />
                        </div>
                      )}
                      {t.type === 'error' && (
                        <div className="icon">
                          <IconX size={18} />
                        </div>
                      )}
                      {t.type === 'loading' && (
                        <div className="icon spinner">
                          <IconLoader2 size={18} />
                        </div>
                      )}
                      {message}
                      <IconButton
                        icon={IconX}
                        onClick={() => toast.dismiss(t.id)}
                      />
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>
          </QueryClientProvider>
        </IntlProvider>
      </TgglProvider>
    </StrictMode>
  </GoogleOAuthProvider>
)
